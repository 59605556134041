.grant {
    color: #AA02FD !important;
}

.donation {
    color: #00aba3 !important;
}

.bg-grant {
    background-color: #AA02FD !important;
}

.bg-donation {
    background-color: #00aba3 !important;
}

.card-grant {
    border-bottom: #AA02FD solid 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-donation {
    border-bottom: #00aba3 solid 4px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.card-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.card-description-content {
    color: #757575 !important;
}

.card-actions {
    flex: 2;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.button-xs {
    min-width: auto !important;
    min-height: auto !important;
    padding: 0px !important;
}

.payment-table > tbody > tr {
    height: auto !important;
}

.payment-table > tbody > tr > td {
    border: none !important;
    padding: 0px 2px 0px 2px !important;
}

.document {
    padding-left: 10px !important;
    color: #757575 !important;    
    overflow: hidden;
    text-overflow: ellipsis;
}

.document-link {
    text-align: right;
    padding-right: 10px !important;
}