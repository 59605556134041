.fund-grant {
    color: #AA02FD !important;
    font-weight: 800 !important;
}

.fund-donation {
    color: #00aba3 !important;
    font-weight: 800 !important;
}

.fund-available {
    color: #00c1dd !important;
    font-weight: 800 !important;
}

.fund-card {
    border-bottom: 4px solid #00c1dd;
}

.fund-card > div {
    padding-bottom: 5px !important;
}

.donations-email {
    color: #757575 !important;
}