.logo-container {
    flex-wrap: nowrap !important;
}

.nav-container {
    flex: 1 !important;
    text-align: right !important;
}

.nav-container > a {
    margin: 2px;
    min-width: 110px;
}
