.header-nowrap {
    flex-wrap: nowrap !important;
}

.main-button {
    min-width: 135px !important;
    margin-right: 10px !important;
}

.audit-card {
    border-bottom: 4px solid #00c1dd;
}