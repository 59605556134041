.change-highlight-new {
    color: #00aba3 !important;
}

.change-highlight-old {
    color: #ab0000 !important;
}

.audit-details-table {
    word-break: break-all;
    word-break: break-word;
    width: 95% !important;
    margin: auto;
    margin-bottom: 30px;
}

.audit-details-table > tbody > tr > td {
    vertical-align: top !important;
}

.cell-min-width {
    min-width: 100px !important;
}

.cell-small-padding {
    padding: 5px !important;
}