.audit-card {
    word-break: break-all;
    word-break: break-word;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.audit-card-content {
    padding-top: 0px !important;
}

.card-actions-small {
    padding: 0px !important;
}

.card-actions {
    flex: 2;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
}

.button-xs {
    min-width: auto !important;
    min-height: auto !important;
    padding: 4px !important;
}

.card-add {
    border-bottom: 4px solid #00aba3;
}

.card-update {
    border-bottom: 4px solid #ffa500;
}

.card-delete {
    border-bottom: 4px solid #ab0000;
}

.bg-add {
    background-color: #00aba3 !important;
}

.bg-update {
    background-color: #ffa500!important;
}

.bg-delete {
    background-color: #ab0000 !important;
}