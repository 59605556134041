body {
    background: #0aba73;
    background: linear-gradient(90deg, #0aba73, #10c0b2,#00c1dd, #10c0b2, #0aba73);
    background-size: 1000% 1000%;

    animation: bg-animation 60s ease infinite;
    padding: 0px;
    margin: 0px;
}

@keyframes bg-animation { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}